import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List, Spin } from 'antd';
import {
  CalendarTwoTone,
  InfoCircleTwoTone,
  MessageTwoTone,
} from '@ant-design/icons';
import { Button, Div, Flyout, FlyoutItem } from '../../framework';
import { Activity } from '../../helper/type';
import { formatIsoTimestamp } from '../../helper/object';
import { useActivityService } from '../../service/activity.service';
import { setActivities } from '../../store/user.slice';
import { RootState } from '../../store/store';
import ActivityItemText from './ActivityItemText';

import './ActivityFlyout.scss';

type Props = {
  onFlyoutClose: () => void;
};

const ActivityFlyout: FunctionComponent<Props> = ({ onFlyoutClose }) => {
  const dispatch = useDispatch();
  const { isActivityFlyoutOpen, loadStack } = useSelector(
    (state: RootState) => state.app
  );
  const { activities, authUser, impersonator } = useSelector(
    (state: RootState) => state.user
  );
  const activityService = useActivityService();

  const [loadMoreKey, setLoadMoreKey] = useState<string>('');
  const initLoading = loadStack.includes('activity/talent/all');
  const isLoadingMore = loadStack.includes('activity/talent/other');

  const { impersonateId, impersonateKey } = impersonator;
  const isImpersonator = impersonateId && impersonateKey;

  const talentId = authUser.username || (isImpersonator ? impersonateId : '');
  useEffect(() => {
    (async () => {
      if (!isActivityFlyoutOpen) return;

      const result = await activityService.listTalentActivities(
        'all',
        talentId
      );
      dispatch(setActivities(result.data));
      setLoadMoreKey(result.key);
    })();

    document.body.style.overflow = isActivityFlyoutOpen ? 'hidden' : '';
  }, [isActivityFlyoutOpen]);

  const sortDesc = (a: Activity, b: Activity) => {
    const timeA = a.lastUpdateAt || a.createdAt;
    const timeB = b.lastUpdateAt || b.createdAt;
    // sort descending
    return timeB.localeCompare(timeA);
  };

  const onLoadMore = async () => {
    const result = await activityService.listTalentActivities(
      'other',
      talentId,
      loadMoreKey
    );
    dispatch(
      setActivities({
        ...activities,
        other: activities.other.concat(result.data.other),
      })
    );
    setLoadMoreKey(result.key);
  };

  const renderActivities = () => {
    const lists = ['pending', 'scheduled']
      .map((key) => {
        const actKey = key as 'pending' | 'scheduled';
        return (
          activities[actKey].length && (
            <List
              key={actKey}
              loading={initLoading}
              dataSource={[...activities[actKey]].sort(sortDesc)}
              renderItem={(act) =>
                actKey === 'pending' ? (
                  <FlyoutItem
                    key={act.activityId}
                    to={`/talent/activity/${act.activityId}`}
                  >
                    <Div flex className='activity-flyout-item'>
                      <MessageTwoTone
                        className='activity-icon'
                        twoToneColor='orange'
                      />
                      <Div>
                        <ActivityItemText act={act} type={actKey} />
                        <br />
                        <small>
                          {formatIsoTimestamp(
                            act.lastUpdateAt || act.createdAt
                          )}
                        </small>
                      </Div>
                    </Div>
                  </FlyoutItem>
                ) : (
                  <FlyoutItem key={act.activityId}>
                    <Div flex className='activity-flyout-item'>
                      <CalendarTwoTone
                        className='activity-icon'
                        twoToneColor='brown'
                      />
                      <Div>
                        <ActivityItemText act={act} type={actKey} />
                        <br />
                        <small>
                          {formatIsoTimestamp(
                            act.lastUpdateAt || act.createdAt
                          )}
                        </small>
                      </Div>
                    </Div>
                  </FlyoutItem>
                )
              }
            ></List>
          )
        );
      })
      .filter(Boolean);

    if (activities.other.length) {
      lists.push(
        <List
          key={'other'}
          loading={initLoading}
          dataSource={[...activities.other]}
          renderItem={(act) => (
            <FlyoutItem key={act.activityId}>
              <Div flex className='activity-flyout-item'>
                <InfoCircleTwoTone className='activity-icon' />
                <Div>
                  <ActivityItemText act={act} type='other' />
                  <br />
                  <small>
                    {formatIsoTimestamp(act.lastUpdateAt || act.createdAt)}
                  </small>
                </Div>
              </Div>
            </FlyoutItem>
          )}
          loadMore={
            !initLoading && loadMoreKey ? (
              <Spin spinning={isLoadingMore}>
                <Button onClick={onLoadMore} rounded block mh='l' mb='l' mt='m'>
                  Load More Activities
                </Button>
              </Spin>
            ) : activities.other.length > 0 ? (
              <div>
                <div className='end-of-list'>
                  <span>That&apos;s all the previous activities!</span>
                </div>
              </div>
            ) : null
          }
        ></List>
      );
    }

    // insert divider
    if (lists.length >= 2) {
      lists.splice(1, 0, <hr key='hr-1' />);
      if (lists.length === 4) {
        lists.splice(3, 0, <hr key='hr-2' />);
      }
    }

    if (lists.length === 0) {
      lists.push(<List key='loading' loading={initLoading}></List>);
    }

    return lists;
  };

  return (
    <Flyout isOpen={isActivityFlyoutOpen} onClose={onFlyoutClose}>
      <Div className='component-activity-flyout'>
        <Div ph='xl'>
          <h2>Activities</h2>
        </Div>
        {renderActivities()}
      </Div>
    </Flyout>
  );
};

export default ActivityFlyout;

import React, { FunctionComponent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Select, Typography } from 'antd';
import { Div } from '../../framework';
import { Activity, Applicant } from '../../helper/type';
import { cancelCastingReason } from '../../helper/constants';
import { formatIsoTimestamp } from '../../helper/object';
import { useActivityService } from '../../service/activity.service';
import { RootState } from '../../store/store';
import { closeModal } from '../../store/app.slice';
import { setActivities } from '../../store/hirer.slice';

import './CancelCastingModal.scss';

export type CancelCastingModalContext = {
  applicant: Applicant;
  invitation: Activity;
};

const { Title } = Typography;

const CancelCastingModal: FunctionComponent = () => {
  const activityService = useActivityService();
  const dispatch = useDispatch();
  const { modal } = useSelector((state: RootState) => state.app);
  const { applicant, invitation } = modal.config
    .context as CancelCastingModalContext;

  const {
    castingType,
    castingBrief,
    duration,
    picContactPrefix,
    picContact,
    castingLocation,
    virtualMeetingLink,
    submitDeadline,
  } = invitation;

  const onFinish = async ({ cancelReason }: Pick<Activity, 'cancelReason'>) => {
    const { activityId, hirerId, roleId, talentId } = invitation;
    await activityService.hirerCancelCasting({
      activityId,
      hirerId,
      roleId,
      talentId,
      cancelReason,
    });

    const { data: updatedActivities } =
      await activityService.listRoleTalentActivity({
        roleId,
        talentId,
        isInterruptive: true,
      });
    dispatch(
      setActivities({ roleId, talentId, activities: updatedActivities })
    );

    dispatch(closeModal());
  };

  return (
    <Div className='cancel-casting-modal'>
      <Title level={4}>Cancel Casting</Title>
      <Div mb='l'>
        Are you sure you want to cancel this casting session with{' '}
        <span className='bold'>{applicant.name}</span>?
      </Div>

      <Div mb='l'>
        <Div className='bold'>Casting Type</Div>
        <span className='capitalize'>{castingType} Casting</span>
      </Div>
      <Div mb='l'>
        <Div className='bold'>Casting Brief</Div>
        {castingBrief?.split('\n').map((line, idx) => (
          <Div key={`${idx}-${line}`}>{line}</Div>
        ))}
      </Div>

      {castingType !== 'self' && (
        <Div mb='l'>
          <Div className='bold'>Duration</Div>
          <span className='capitalize'>
            {duration?.value} {duration?.period}
          </span>
        </Div>
      )}

      {castingType === 'physical' && (
        <>
          <Div mb='l'>
            <Div className='bold'>Contact of person in charge</Div>
            <span>
              {picContactPrefix}
              {picContact}
            </span>
          </Div>
          <Div mb='l'>
            <Div className='bold'>Casting Location Address</Div>
            <span>{castingLocation}</span>
          </Div>
        </>
      )}

      {castingType === 'virtual' && (
        <Div mb='l'>
          <Div className='bold'>Virtual Meeting Link</Div>
          <span>{virtualMeetingLink}</span>
        </Div>
      )}

      {castingType === 'self' && submitDeadline && (
        <Div mb='l'>
          <Div className='bold'>Casting Video Submission deadline</Div>
          <span>{formatIsoTimestamp(submitDeadline)}</span>
        </Div>
      )}

      <Form onFinish={onFinish} layout='vertical'>
        <Form.Item
          name='cancelReason'
          label='Reason for cancelling'
          rules={[{ required: true, message: 'Please choose one reason' }]}
        >
          <Select
            options={Object.keys(cancelCastingReason).map((key) => ({
              label:
                cancelCastingReason[key as keyof typeof cancelCastingReason],
              value: key,
            }))}
          ></Select>
        </Form.Item>
        <Form.Item>
          <Div mb='m'>
            <Button block type='primary' htmlType='submit'>
              Cancel Casting
            </Button>
          </Div>
          <Button block onClick={() => dispatch(closeModal())}>
            No, let me reconsider
          </Button>
        </Form.Item>
      </Form>
    </Div>
  );
};

export default CancelCastingModal;

import { Typography } from 'antd';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { SendOutlined } from '@ant-design/icons';
import { Div } from '../../../framework';
import { Activity } from '../../../helper/type';
import { formatIsoTimestamp } from '../../../helper/object';
import ActivityItemText from '../../../component/ActivityFlyout/ActivityItemText';

import './PendingActivityCard.scss';

const { Text } = Typography;

type Props = {
  activity: Activity;
};

const PendingActivityCard: FunctionComponent<Props> = ({ activity }) => {
  const history = useHistory();

  return (
    <Div className='pending-activity-card' mb='xl'>
      <Div flex p='s' pl='l' pr='l' className='role-title'>
        <Div>
          {activity.projectTitle} - {activity.roleName}
        </Div>
      </Div>
      <Div ph='l' pv='m' className='role-details'>
        <ActivityItemText act={activity} type='pending' />
        <br />
        <Div className='text-right'>
          <small>
            {formatIsoTimestamp(activity.lastUpdateAt || activity.createdAt)}
          </small>
        </Div>
      </Div>
      <Div className='action' flex>
        <button
          onClick={() =>
            history.push(`/talent/activity/${activity.activityId}`)
          }
        >
          <SendOutlined />
          <Text>Reply</Text>
        </button>
      </Div>
    </Div>
  );
};

export default PendingActivityCard;

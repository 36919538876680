import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Form, Radio, Spin, message } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';
import Paragraph from 'antd/lib/typography/Paragraph';
import Title from 'antd/lib/typography/Title';
import moment, { Moment } from 'moment';
import VideoUpload from '../../../../component/VideoUpload/VideoUpload';
import { Div } from '../../../../framework';
import { formatIsoTimestamp } from '../../../../helper/object';
import { Activity, Schedule } from '../../../../helper/type';
import { useActivityService } from '../../../../service/activity.service';
import { useFileManageService } from '../../../../service/filemanage.service';
import { TALENT_ROUTES } from '../../../../route/constant';
import { RootState } from '../../../../store/store';

import './Invitation.scss';

type Props = {
  activity: Activity;
};

type Timeslot = { start: string; end: string };
type FormValue = {
  scheduledDatetime?: { date: string; slot: string };
  selfCastingVideo?: string;
};

const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'hh:mm a';
const ISO_TIME_FORMAT = 'HH:mm';

const Invitation: FunctionComponent<Props> = ({ activity }) => {
  const { loadStack } = useSelector((state: RootState) => state.app);
  const { authUser, impersonator } = useSelector(
    (state: RootState) => state.user
  );
  const activityService = useActivityService();
  const fileManageService = useFileManageService();
  const history = useHistory();

  const [scheduleOptions, setScheduleOptions] = useState<
    Record<string, Timeslot[]>
  >({});
  const [duration, setDuration] = useState<Activity['duration']>();
  const [takenSlots, setTakenSlots] = useState<
    Array<{ date: string; slot: { start: string; end: string } }>
  >([]);
  const [errorMessage, setErrorMessage] = useState('');
  const isLoading =
    loadStack.includes(`activity/schedule/get/${activity.scheduleId}`) ||
    loadStack.includes(`activity/schedule/taken/${activity.scheduleId}`);

  const [selfCastingVid, setSelfCastingVid] = useState<File>();

  const { impersonateId, impersonateKey } = impersonator;
  const isImpersonator = impersonateId && impersonateKey;

  const talentId = authUser.username || (isImpersonator ? impersonateId : '');

  const loadTakenSlots = async () => {
    const { scheduleId, duration } = activity;
    if (scheduleId && duration) {
      const othersTaken: Activity[] =
        await activityService.getTalentTakenTimeslot(scheduleId);
      setTakenSlots(
        othersTaken
          .filter((other) => other.scheduledDatetime)
          .map((other) => {
            const taken = moment(other.scheduledDatetime);
            return {
              date: taken.format(DATE_FORMAT),
              slot: {
                start: taken.format(ISO_TIME_FORMAT),
                end: taken
                  .add(other.duration?.value, other.duration?.period)
                  .format(ISO_TIME_FORMAT),
              },
            };
          })
      );
    }
  };

  const breakdownSlots = (
    start: Moment,
    end: Moment,
    duration: Activity['duration']
  ) => {
    const slots: Timeslot[] = [];
    if (duration) {
      while (start.isBefore(end)) {
        slots.push({
          start: start.format(ISO_TIME_FORMAT),
          end: start
            .add(duration.value, duration.period)
            .format(ISO_TIME_FORMAT),
        });
      }
    }
    return slots;
  };
  useEffect(() => {
    (async () => {
      const { scheduleId } = activity;
      if (scheduleId) {
        const schedule: Schedule = await activityService.getHirerSchedule(
          scheduleId
        );
        const flattenRange: Timeslot[] = [];
        Object.values(schedule.slots).forEach((timeRange) =>
          flattenRange.push(...timeRange)
        );

        const options: Schedule['slots'] = {};
        flattenRange
          .sort((a, b) => a.start.localeCompare(b.start))
          .forEach((a) => {
            const start = moment(a.start);
            const end = moment(a.end);
            const date = start.format(DATE_FORMAT);
            options[date] = (options[date] ?? []).concat(
              breakdownSlots(start, end, activity.duration)
            );
          });

        setScheduleOptions(options);
        setDuration(activity.duration);

        loadTakenSlots();
      }
    })();
  }, [activity]);

  const replyData = () => ({
    activityId: activity.activityId,
    hirerId: activity.hirerId,
    roleId: activity.roleId,
    applicationId: activity.applicationId,
    actionType: activity.actionType,
    scheduleId: activity.scheduleId,
    castingType: activity.castingType,
    duration: activity.duration,
  });

  const handleSubmit = async (values: FormValue) => {
    let fileUrl;
    if (activity.castingType === 'self') {
      fileUrl = await uploadSelfCastingVid();
      if (!fileUrl) {
        setErrorMessage('Please upload your self casting video.');
        return;
      }

      values.selfCastingVideo = fileUrl;
    } else if (values.scheduledDatetime == null) {
      setErrorMessage('Please select a timeslot.');
      return;
    }

    setErrorMessage('');

    const { scheduledDatetime, ...vals } = values;
    const { date, slot } = scheduledDatetime ?? {};
    try {
      await activityService.talentReplyInvitation(talentId, {
        ...replyData(),
        ...vals,
        reply: 'accept',
        ...(activity.castingType === 'self' && {
          scheduledDatetime: new Date().toISOString(),
        }),
        ...(activity.castingType !== 'self' && {
          scheduledDatetime: scheduledDatetime
            ? moment(`${date} ${slot}`, 'YYYY-MM-DD HH:mm').toISOString()
            : undefined,
        }),
      });

      history.push(TALENT_ROUTES.DASHBOARD);
    } catch (e) {
      message.error({ content: 'Slot has been taken by other talent.' });
      loadTakenSlots();
    }
  };

  const handleRejectInvitation = async () => {
    await activityService.talentReplyInvitation(talentId, {
      ...replyData(),
      reply: 'reject',
    });
    history.push(TALENT_ROUTES.DASHBOARD);
  };

  const isSlotTaken = (date: string, slot: Timeslot) => {
    const { start, end } = slot;
    return (
      takenSlots.find(
        (taken) =>
          taken.date === date &&
          start < taken.slot.end &&
          end > taken.slot.start
      ) != null
    );
  };

  const uploadSelfCastingVid = async () => {
    if (!selfCastingVid) {
      return;
    }

    const { url: fileUrl } = await fileManageService.uploadFile({
      scope: 'activity-invitation',
      directory: `role-${activity.roleId}`,
      file: selfCastingVid,
    });

    return fileUrl;
  };

  const today = moment().format(DATE_FORMAT);
  const selfCastingInvExpired =
    activity.castingType === 'self' &&
    new Date().toISOString() > (activity.submitDeadline ?? '');
  return (
    <Div className='component-talent-invitation'>
      <Title level={4} className='capitalize'>
        Reply {activity.actionType.toLowerCase()} Invitation
      </Title>
      <Paragraph>
        Congrats! You&apos;ve been invited to cast the{' '}
        <span className='bold'>{activity.roleName}</span> role for{' '}
        <span className='bold'>{activity.projectTitle}</span>! Please select a
        suitable time for casting your role.
      </Paragraph>

      {activity.castingType === 'self' ? (
        <Paragraph>
          <Div className='bold'>Submit before</Div>
          {activity.submitDeadline &&
            formatIsoTimestamp(activity.submitDeadline)}
        </Paragraph>
      ) : (
        <Paragraph>
          Duration: {duration?.value} {duration?.period}
        </Paragraph>
      )}

      <Spin spinning={isLoading}>
        <Form layout='vertical' onFinish={handleSubmit}>
          {activity.castingType !== 'self' &&
            Object.keys(scheduleOptions).map((date) => (
              <Form.Item
                key={date}
                name='scheduledDatetime'
                label={<span className='bold italic'>{date}</span>}
              >
                <Radio.Group
                  buttonStyle='solid'
                  className='radio-group-timeslot'
                >
                  {scheduleOptions[date].map((slot) => {
                    const slotStr = slot.start;
                    return (
                      <Radio.Button
                        key={`${date}-${slotStr}`}
                        className='radio-timeslot'
                        disabled={date < today || isSlotTaken(date, slot)}
                        value={{ date: date, slot: slotStr }}
                      >
                        {moment(slot.start, ISO_TIME_FORMAT).format(
                          TIME_FORMAT
                        )}
                      </Radio.Button>
                    );
                  })}
                </Radio.Group>
              </Form.Item>
            ))}

          <Div className='bold'>Casting Brief</Div>
          <Paragraph>
            {activity.castingBrief?.split('\n').map((str, idx) => (
              <>
                <span key={idx}>{str}</span>
                <br />
              </>
            ))}
          </Paragraph>

          {activity.castingType === 'self' && !selfCastingInvExpired ? (
            <Div pb='m'>
              <Div className='bold'>Self Casting Video</Div>
              <VideoUpload onChange={(vid) => setSelfCastingVid(vid)} />
            </Div>
          ) : activity.castingType === 'self' && selfCastingInvExpired ? (
            <Div pb='m'>
              <span style={{ color: 'red', fontStyle: 'italic' }}>
                Invitation expired.
              </span>
            </Div>
          ) : null}

          {activity.castingType === 'physical' && (
            <>
              <Div pb='m'>
                <Div className='bold'>Casting Location</Div>
                <Button
                  type='link'
                  icon={<EnvironmentOutlined />}
                  href={`http://maps.google.com/maps?t=m&q=loc:${activity.castingLocationLat}+${activity.castingLocationLng}`}
                  target='_blank'
                  className='casting-location-address'
                >
                  <span>{activity.castingLocation}</span>
                </Button>
              </Div>
              <Div className='bold'>Contact Person</Div>
              <Paragraph>
                {activity.picContactPrefix}
                {activity.picContact}
              </Paragraph>
            </>
          )}

          {errorMessage ? (
            <span style={{ color: 'red' }}>{errorMessage}</span>
          ) : null}
          <Form.Item>
            {selfCastingInvExpired ? null : (
              <Button
                type='primary'
                block
                htmlType='submit'
                style={{ marginBottom: '1rem' }}
              >
                Submit
              </Button>
            )}
            <Button block onClick={handleRejectInvitation}>
              {selfCastingInvExpired ? 'Dismiss' : 'Reject'}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Div>
  );
};

export default Invitation;

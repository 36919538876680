import React, { FunctionComponent, useCallback, useState } from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import {
  AddApplicantViewCountPayload,
  useActivityService,
} from '../../service/activity.service';

import './MediaGalleryModal.scss';

type MediaGalleryModalContext = {
  handleVideoPlay?: {
    name: string;
    payload: AddApplicantViewCountPayload;
  };
  gallery: Array<ReactImageGalleryItem & { type: string }>;
  index: number;
};

const MediaGalleryModal: FunctionComponent = () => {
  const { modal } = useSelector((state: RootState) => state.app);
  const context = (modal.config.context as MediaGalleryModalContext) || {};
  const activityService = useActivityService();
  const [index, setIndex] = useState(context.index);

  const handleVideoPlay = useCallback(async () => {
    if (context.handleVideoPlay) {
      const handlerContext = context.handleVideoPlay;
      const handlers: Record<string, () => Promise<unknown>> = {
        addApplicantViewCount: async () => {
          await activityService.addApplicantViewCount(
            handlerContext.payload,
            'video'
          );
        },
      };
      await handlers[handlerContext.name]?.call(null);
    }
  }, [context.handleVideoPlay?.name, context.handleVideoPlay?.payload]);

  const itemsWithVideo: ReactImageGalleryItem[] =
    context.gallery.map((item) => {
      return item.type === 'video'
        ? {
            ...item,
            renderItem: () => (
              <div className='video-wrapper'>
                <video
                  width='100%'
                  height='auto'
                  controls
                  controlsList='nofullscreen'
                  onPlay={handleVideoPlay}
                >
                  <source src={item.original} type='video/mp4' />
                  Your browser does not support the video tag.
                </video>
              </div>
            ),
          }
        : item;
    }) || [];

  return (
    <div className='media-gallery-modal'>
      <ImageGallery
        showPlayButton={false}
        thumbnailPosition='top'
        lazyLoad
        items={itemsWithVideo}
        startIndex={index}
        showNav={window.screen.width > 1000}
        onSlide={setIndex}
      />
    </div>
  );
};

export default MediaGalleryModal;

import moment from 'moment';

export const isEmpty = (obj: { [key: string]: any }) => {
  return !Object.keys(obj).length;
};

export const removeEmptyStringProps = (obj: { [key: string]: any }) => {
  const updatedObj = { ...obj };
  const keys = Object.keys(updatedObj);
  for (let i = 0; i < keys.length; i++) {
    if (typeof updatedObj[keys[i]] === 'string' && !updatedObj[keys[i]]) {
      delete updatedObj[keys[i]];
    }
  }

  return updatedObj;
};

export const formatNumber = (number: number) => {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + 'M';
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1) + 'K';
  } else {
    return number.toString();
  }
};

export const formatText = (s: string) => {
  return s.replace('subscribers', '');
};

export const convertTime = (s: any) => {
  return moment(s).hour(0).minute(0).second(0).millisecond(0).toISOString();
};

export const getMilliSeconds = ({
  days = 0,
  hours = 0,
  minutes = 0,
  seconds = 0,
}: {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}) => {
  hours += days * 24;
  minutes += hours * 60;
  seconds += minutes * 60;
  return seconds * 1000;
};

export const formatIsoTimestamp = (date: string) =>
  moment(date).format('hh:mm a DD MMM YYYY');

export const formatIsoDate = (date: string) =>
  moment(date).format('DD MMM YYYY');

export const roundDecimalPlace = (num: number, decimalPlace: number) => {
  const pow = Math.pow(10, decimalPlace);
  return Math.round(num * pow) / pow;
};
